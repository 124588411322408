<template>
<div v-if="webhookInfo">
  <b-row class="d-flex justify-content-between header mb-1">
    <b-col>
      <h1 class="serverName text-primary ml-2">
        <strong>
          {{ `${webhookInfo.identifier}` }}
        </strong>
      </h1>
    </b-col>
  </b-row>
  <b-row class="ml-2 mb-2 d-flex justify-content-between">
    <div>
      {{ $t("common.terms.created_at") }}
      <strong class="text-primary pl-50">
        {{ webhookInfo.created_at }}
      </strong>
    </div>
    <div>
      {{ $t("common.terms.updated_at") }}
      <strong class="text-primary pl-50 mr-3">
        {{ webhookInfo.updated_at }}
      </strong>
    </div>
  </b-row>
  <b-tabs horizontal nav-wrapper-class="nav-bottom" class="ml-2">
    <b-tab title="Info">
      <b-row>
        <b-col md="6" cols="12" class="mt-4">
          <b-row>
            <b-col class="mb-1" cols="12">
              <b-form-group label="webhook_identifier" label-for="webhook-identifier" label-cols-md="4">
                <b-input-group v-if="webhookInfo.identifier" class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="BoxIcon" />
                  </b-input-group-prepend>
                  <b-form-input id="webhook-identifier" :value="`  ${webhookInfo.identifier}`" readonly />
                  <b-input-group-append is-text>
                    <div class="ml-1 btn btn-warning text-white py-25 px-75" @click="editWebhook('Identifier')">
                      <feather-icon class="cursor-pointer" :icon="`Edit2Icon`" />
                    </div>
                  </b-input-group-append>
                </b-input-group>
                <div v-else>
                  <b-skeleton type="input"></b-skeleton>
                </div>
              </b-form-group>
            </b-col>

            <b-col class="mb-1" cols="12">
              <b-form-group label="Status" label-for="base-path" label-cols-md="4">
                <b-input-group v-if="webhookInfo.enum_status.label" class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="LinkIcon" />
                  </b-input-group-prepend>
                  <b-form-input id="base-path" placeholder="Plan Category" readonly :value="`  ${webhookInfo.enum_status.label}`" />
                  <b-input-group-append is-text>
                    <div class="ml-1 btn btn-warning text-white py-25 px-75" @click="editWebhook('Enum_Status')">
                      <feather-icon class="cursor-pointer" :icon="`Edit2Icon`" />
                    </div>
                  </b-input-group-append>
                </b-input-group>
                <div v-else>
                  <b-skeleton type="input"></b-skeleton>
                </div>
              </b-form-group>
            </b-col>

            <b-col class="mb-1" cols="12">
              <b-form-group :label="$t('info.token')" label-for="gx-webhook-id" label-cols-md="4">
                <b-input-group v-if="webhookInfo.token" class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <div class="btn btn-info text-white py-25 px-75" v-clipboard:copy="webhookInfo.token" v-clipboard:success="onCopy" v-b-tooltip.hover.v-black :title="$t('tootltip.clipboard')">
                      <feather-icon class="cursor-pointer" icon="FileTextIcon" />
                    </div>
                  </b-input-group-prepend>
                  <b-form-input id="gx-webhook-id" readonly :type="webhookTokenInputType" :value="`${webhookInfo.token}`" />
                  <b-input-group-append is-text>
                    <div class="btn btn-secondary text-white py-25 px-75" @click="toggleWebhookTokenVisibility()">
                      <feather-icon class="cursor-pointer" :icon="webhookTokenIcon" />
                    </div>
                    <div class="ml-1 btn btn-warning text-white py-25 px-75" @click="refreshToken()">
                      <feather-icon class="cursor-pointer" icon="RefreshCwIcon" />
                    </div>
                  </b-input-group-append>
                </b-input-group>
                <div v-else>
                  <b-skeleton type="input"></b-skeleton>
                </div>

                <div>
                  <b-button v-clipboard:copy="URL" v-clipboard:success="onCopy" variant="outline-info" class="mt-2 d-inline-block">
                    <feather-icon class="cursor-pointer" icon="FileTextIcon" />
                    {{ $t("webhook.button.copy_webhook_endpoint") }}
                  </b-button>
                  <b-button v-clipboard:copy="URL_PATH" v-clipboard:success="onCopy" variant="outline-info" class="mt-2 ml-md-2 d-inline-block">
                    <feather-icon class="cursor-pointer" icon="FileTextIcon" />
                    {{ $t("webhook.button.copy_webhook_endpoint_path_mode") }}
                  </b-button>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="6" cols="12">
          <scoped-vars :identifier="webhookInfo.identifier" scope="@WEBHOOK" />
        </b-col>
      </b-row>
    </b-tab>
  </b-tabs>
  <base-modal />
</div>
</template>

<script>
import BaseModal from "@/layouts/components/BaseModal.vue";
import custom from "@/custom";
import {
  makeToast
} from "@/layouts/components/Popups.js";
import ScopedVars from "@/layouts/components/Transmission/ScopedVars.vue"

import {
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormTextarea,
  BForm,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BRow,
  BCol,
  BSkeleton,
  BTabs,
  BTab,
  BCardText,
  VBTooltip,
} from "bootstrap-vue";

export default {
  components: {
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    BForm,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BRow,
    BCol,
    BTabs,
    BTab,
    BCardText,
    BaseModal,
    BSkeleton,
    VBTooltip,
    ScopedVars,
  },
  data() {
    return {
      webhookInfo: null,
      webhookTokenIcon: "EyeIcon",
      webhookTokenInputType: "password",
      webhookTokenVisible: false,
    };
  },
  computed: {
    URL() {
      const transmissionIdentifier = this.$store.getters.getSelectedTransmissionGroup ? this.$store.getters.getSelectedTransmissionGroup.identifier : null;
      const webhookIdentifier = this.webhookInfo.identifier;
      const webhookToken = this.webhookInfo.token;

      const url = `https://instance.fique.online/webhook/${transmissionIdentifier}/${webhookIdentifier}?token=${webhookToken}`;
      return url;
    },
    URL_PATH() {
      const transmissionIdentifier = this.$store.getters.getSelectedTransmissionGroup ? this.$store.getters.getSelectedTransmissionGroup.identifier : null;
      const webhookIdentifier = this.webhookInfo.identifier;
      const webhookToken = this.webhookInfo.token;

      const url = `https://instance.fique.online/webhook/${transmissionIdentifier}/${webhookIdentifier}/${webhookToken}`;
      return url;
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  created() {
    this.$root.$refs[this.$route.meta.pageTitle] = this;
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {

      this.fetchWebhookById();
    },
    fetchWebhookById() {
      this.$store
        .dispatch("getWebhookById", {
          ...this.$route.params
        })
        .then((response) => {
          console.log(response);
          this.$store.commit("SET_CURRENT_WEBHOOK", response);
          this.webhookInfo = response;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    toggleWebhookTokenVisibility() {
      this.webhookTokenVisible = !this.webhookTokenVisible;
      if (this.webhookTokenVisible) {
        this.webhookTokenIcon = "EyeOffIcon";
        this.webhookTokenInputType = "text";
      } else {
        this.webhookTokenIcon = "EyeIcon";
        this.webhookTokenInputType = "password";
      }
    },
    editWebhook(action) {
      this.$store.dispatch("setWebhookAction", action).then(() => {
        this.$store
          .dispatch("setModalObj", `editWebhook${action}`)
          .then(() => {
            this.$store.dispatch("setActiveModal", true);
          })
          .catch(() => {
            console.log(error);
          });
      });
    },
    refreshToken() {
      let modalData = custom.confirmModalStructure.changeWebhookToken;
      this.$bvModal
        .msgBoxConfirm(this.$t(modalData.message), modalData.config)
        .then((value) => {
          if (value) {
            this.$store.dispatch("setLoadingState", true);
            this.$store
              .dispatch("changeWebhookToken", {
                ...this.$route.params
              })
              .then(() => {
                this.$store.dispatch("setLoadingState", false);
                this.init();

                makeToast(custom.successMessages.default);
              })
              .catch((error) => {
                this.$store.dispatch("setLoadingState", false);

                makeToast(custom.errorMessages.d);
              });
          } else {
            makeToast(custom.warningMessages[action]);
          }
        });
    },
    onCopy() {
      makeToast({
        title: this.$t("common.toast.copy_to_clipboard.success.title"),
        text: this.$t("common.toast.copy_to_clipboard.success.message"),
        variant: "success",
        icon: "CheckIcon",
      });
    },
  },
};
</script>
